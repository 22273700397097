import dispatchEvent from '../utils/dispatch-event';

const classes = {
  hide: 'invisible',
  overflowHidden: 'overflow-hidden',
};

class PageOverlay {
  /**
   * @param {Element} element
   */
  constructor(element) {
    this.overlay = element;

    this.registerEventListeners();
  }

  /**
   * @return {'inactive'|'active'}
   */
  get state() {
    return this.overlay.dataset.state;
  }

  set state(state) {
    this.overlay.dataset.state = state;
  }

  registerEventListeners() {
    this.overlay.addEventListener('click', () => {
      dispatchEvent('drawer:toggle', this.overlay, {
        detail: {
          drawer: this.overlay,
        },
      });
    });

    document.addEventListener('page-overlay:activate', () => this.activate());
    document.addEventListener('page-overlay:deactivate', () =>
      this.deactivate()
    );
  }

  deactivate() {
    this.overlay.classList.add(classes.hide);
    this.overlay.classList.remove('visible');
    document.body.classList.remove(classes.overflowHidden);

    this.state = 'inactive';
  }

  activate() {
    this.overlay.classList.remove(classes.hide);
    this.overlay.classList.add('visible');
    document.body.classList.add(classes.overflowHidden);

    this.state = 'active';
  }
}

const createOverlay = element => new PageOverlay(element);

export default createOverlay;